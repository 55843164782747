<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Payment Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Month Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Payment Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select class="mg-t-5 mr-2" v-model="params.session_year" @change="searchFilter">
            <option v-for="(year,index) in sessionYears" :key="index" :value="year">{{year}}/{{parseInt(year.substring(year.length-2)) + 1}}</option>
          </select>
          <select v-model="params.branch" class="filter-input mg-t-5 mr-2" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in paymentDataAttrbutes"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/payment-report">Transaction Wise</option>
              <option value="/general-report/payment-report/method-wise">Method Wise</option>
              <option value="/general-report/payment-report/branch-wise">Branch Wise</option>
              <option value="/general-report/payment-report/day-wise">Day Wise</option>
              <option value="/general-report/payment-report/month-wise">Month Wise</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-35p">Date</th>
              <th class="wd-15p">Opening</th>
              <th class="wd-15p">Incoming</th>
              <th class="wd-15p">Outgoing</th>
              <th class="wd-15p">Closing</th>
            </tr>
          </thead>
          <tbody v-if="!loading && monthWiseDatas.length > 0">
            <tr v-for="(payment,index) in monthWiseDatas" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <th>{{payment.month}} - {{payment.year}}</th>
              <td>{{parseDigitForSlip(payment.opening_balance)}}</td>
              <td>{{parseDigitForSlip(payment.incoming)}}</td>
              <td>{{parseDigitForSlip(payment.outgoing)}}</td>
              <td>{{parseDigitForSlip(payment.closing_balance)}}</td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
              <tr>
              <td class="text-center" colspan="11">{{error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td class="text-center" colspan="11">Loading...</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && monthWiseDatas.length == 0">
              <tr>
              <td class="text-center" colspan="11">No Records Found...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  data(){
    return{
      sessionYears:['2021'],
      route:this.$route.path,
      params:{
        pageIndex:20,
        branch:'',
        bank:'',
        type:"",
        from:'',
        session_year:2021,
        searched:'',
        to:'',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        offset:0,
      },
      excel_params:{
        pageIndex:20,
        branch:'',
        bank:'',
        type:"",
        from:'',
        session_year:2021,
        searched:'',
        to:'',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        offset:0,
      },
      loading : false,
      error :false,
      error_message:'',
      page:1,
      pageSerialNo:1,
      totalPageCount:'',
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("paymentReport",['monthWiseDatas','paymentDataAttrbutes']),
  },
  methods: {
    print(){
      window.print();
    },
    filterReportType() {
      if(this.$route.path!=this.route) {
        this.$router.push(this.route);
      }
    },
    searchFilter(){
        this.page=1;
        this.pageSerialNo=1;
        this.params.offset = 0;
        this.ajaxRequest();
    },
   searchKeyword:_.debounce(function(){
        this.page=1;
        this.pageSerialNo=1;
        this.params.offset = 0;
        this.ajaxRequest();
    },1000),
    ajaxRequest() {
      this.loading = true;
      Services.getMonthWiseReport(this.params)
        .then((res) => {
          this.$store.commit("paymentReport/setMonthWiseDatas",res.data.data.data);
          this.$store.commit("paymentReport/setPaymentDataAttrbutes",res.data.data.attributes);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.$store.commit("paymentReport/setMonthWiseDatas", []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.ajaxRequest();
    },
     download(){
      Services.getMonthWiseExcel(this.excel_params).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'payment-month-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>